/* eslint-disable camelcase */
import { z } from 'zod';
import { accountDetailSchema } from './AccountDetail';
import { optionalNumber } from './OptionalNumber';
import { CaseMappings } from '@apparatix/utilities/CaseMappings';
import { emailSchema, addressableToPayload } from './Addressable';
import { AssociationAccount, OptionsUser } from '@apparatix/types/Leads';
import { owner } from './Owner';

export const newAccountSchema = accountDetailSchema.merge(z.object({
  id: optionalNumber(),
  name: z.string().nonempty({ message: 'An Account must have a name' }),
  type: z.string().nonempty({ message: 'An Account must have a type' }),
  displayType: z.string().optional(),
  emails: emailSchema.array().optional(),
  isAllowedSelection: z.boolean().optional(),
  // A key is used as a unique identifier in lieu of an ID since the object hasn't been sent to the backend
  key: z.string().optional().default(() => crypto.randomUUID()),
  contact_ids: z.object({
    id: z.number().positive(),
    labelId: optionalNumber(),
  })
    .array()
    .optional()
    .default(() => []),
  legalName: z.string().optional(),
  owner: owner.optional(),
}));

export const newAccountPayloadTransformer = newAccountSchema.transform(account => {
  delete account.key;
  delete account.isAllowedSelection;

  const caseConverter = new CaseMappings();

  return {
    data: {
      account: {
        ...caseConverter.camelToSnake(account) as object,
        company_name: account.name ?? account.legalName,
        legal_name: account.legalName ?? account.name,
        owner: account.owner?.id,
        markets: Array.isArray(account.markets)
          ? account.markets.map(market => market.market)
          : undefined,
        industry: account.industry ? `${account.industry.description}|${account.industry.code}` : undefined,
        ...addressableToPayload.parse(account),
      },
    },
  };
});

export const transformSearchResult = (result: AssociationAccount, accountOwner: OptionsUser) => {
  const transformer = z.object({
    c2Id: z.number(),
    c2Name: z.string(),
    c2Type: z.string(),
    c2RawType: z.string(),
    ownerId: z.number(),
    isAllowedSelection: z.boolean(),
  }).transform(data => ({
    id: data.c2Id,
    name: data.c2Name,
    type: data.c2RawType,
    displayType: data.c2Type,
    key: crypto.randomUUID(),
    owner: accountOwner,
    isAllowedSelection: data.isAllowedSelection,
  }));

  try {
    return transformer.parse(result);
  } catch {
    return undefined;
  }
};

export const transformNewAccountResponse = z.object({
  c2_name: z.string(),
  c2_id: z.number(),
  c2_type: z.string(),
  displayType: z.string(),
})
  .transform(data => ({
    id: data.c2_id,
    name: data.c2_name,
    type: data.c2_type.includes('Advertiser') ? 'Advertiser' : data.c2_type,
    displayType: data.displayType,
  }));

export const transformToAssociation = accountDetailSchema
  .merge(z.object({
    type: z.string(),
    displayType: z.string(),
    legalName: z.string().optional().nullable(),
  }))
  .transform(data => ({
    c2Id: data?.id,
    c2Name: data?.name,
    c2Type: data?.displayType,
    c2RawType: data?.type,
    ownerId: data?.owner?.id,
    addresses: data?.addresses,
  }));

export type NewAccountPayload = z.infer<typeof newAccountPayloadTransformer>;
export type PendingAccount = z.infer<typeof newAccountSchema>;

// This needs to be in a try/catch block when used, because parsing will throw an exception if the data fails schema validation
// it's not in a try/catch here so that it's easier for the consumer to get the error message[s]
export const newAccountToPayload = (data: unknown) => newAccountPayloadTransformer.parse(data);

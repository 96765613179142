import { z } from 'zod';
import { SchemaErrors } from './SchemaErrors';
import { owner } from './Owner';
import { temperature } from './Temperature';
import { opportunityStatus } from './OpportunityStatus';
import { optionalNumber } from './OptionalNumber';
import { optionalString } from './OptionalString';

export const leadDetail = z.object({
  id: optionalNumber(),
  name: z.string()
    .nonempty('Name is a required field')
    .refine(name => name.trim().length > 0, 'Name cannot be just whitespace.'),
  owner,
  status: opportunityStatus,
  temperature: temperature.optional(),
  type: z.union([z.literal('Lead'), z.literal('Opportunity')]),
  value: optionalNumber(),
  source: optionalString.transform(val => val === '' || val === null ? undefined : val),
});

export type LeadDetailSchema = z.infer<typeof leadDetail>;
export type LeadDetailValidationErrors = SchemaErrors<LeadDetailSchema>;
